import React, { useState } from "react";
import styled from "styled-components";

import AccordionDesktopOpen from "./components/AccordionDesktopOpenComponent";
import AccordionDesktopClosed from "./components/AccordionDesktopClosedComponent";

const handleFAQDestructure = ({ fields }) => {
  let faqs = [];
  if (!fields) return null;
  fields.forEach((faq) => {
    faqs.push({
      answer: faq?.answer ? faq?.answer[0].text : "",
      question: faq?.question ? faq?.question[0].text : "",
    });
  });
  return faqs;
};

const FAQDesktopComponent = (primary) => {
  const faqs = handleFAQDestructure(primary);
  const [activeFaq, setActiveFaq] = useState(-1);
  if (!faqs) return null;
  return (
    <FaqDesktop>
      <FaqContents>
        <FrequentlyAskedQue>
          Frequently Asked{" "}
          <FrequentlyAskedQueEmphasis2>Questions</FrequentlyAskedQueEmphasis2>
        </FrequentlyAskedQue>
        {faqs.map((faq, index) => {
          if (activeFaq === index)
            return (
              <AccordionDesktopOpenItem
                question={faq.question}
                answer={faq.answer}
                close={() => setActiveFaq(-1)}
              />
            );
          else
            return (
              <AccordionDesktopClosedItem
                question={faq.question}
                answer={faq.answer}
                open={() => setActiveFaq(index)}
              />
            );
        })}
      </FaqContents>
    </FaqDesktop>
  );
};

export const FaqDesktop = styled.div`
  padding: 0px 0px 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("https://static.overlay-tech.com/assets/3a8ab123-a48e-4b00-8b55-99eb95d72e7d.png");
  background-repeat: no-repeat;
  background-color: rgb(230, 243, 219);
  background-position: 50% 65%;

  @media only screen and (max-width: 800px) {
    background-color: ${(props) => props.theme.colors.greenBackground};
    padding: 0px 0px 63px;
  }
`;

export const FaqContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 119px;
`;

export const FrequentlyAskedQue = styled.p`
  width: 690px;
  font-family: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.lineHeight};
  color: rgba(0, 51, 61, 1);
  text-align: center;
  margin-bottom: 48px;
  @media only screen and (max-width: 800px) {
    max-width: 366px;
    color: rgba(0, 51, 61, 1);
    margin-bottom: 16px;
  }
`;

export const FrequentlyAskedQueEmphasis2 = styled.strong`
  font-family: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.lineHeight};
  color: ${(props) => props.theme.textColors.greenPrimary};
`;

export const AccordionDesktopOpenItem = styled(AccordionDesktopOpen)`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const AccordionDesktopClosedItem = styled(AccordionDesktopClosed)`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export default FAQDesktopComponent;
