import React from "react";
import styled from "styled-components";
import RightArrowIcon from "../../icons/RightArrowIcon";
import { routeHelper } from "../../../utils/routeHelper";
import { pageTypes } from "../../models/prismicTypes";
import {
  LinkWrapper,
  Wrapper,
  Title,
  ContentWrapper,
  Content,
  ImageWrapper,
} from "../../blog/Blog";

const RelatedPageCard = (related_page) => {
  return (
    <LinkWrapper
      href={`${routeHelper(
        related_page.type === pageTypes.blog
          ? `/blogs/${related_page?.url}`
          : `/${related_page?.url}`
      )}`}
    >
      <PageWrapper>
        <ContentWrapper>
          <Content>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <Title>{related_page.title}</Title>
              <RightArrowIcon />
            </div>
            <PageContent>{related_page.description}</PageContent>
          </Content>
        </ContentWrapper>
        <ImageWrapper>
          <img src={related_page.image} width="100%" height="100%" alt={related_page.title} />
        </ImageWrapper>
      </PageWrapper>
    </LinkWrapper>
  );
};

const PageWrapper = styled(Wrapper)`
  max-width: 374px;
  margin-left: auto;
  margin-right: auto;
`;

const PageContent = styled.p`
  margin-top: 16px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.navyPrimary};
  opacity: 0.6;
  line-height: 20px;
`;

export default RelatedPageCard;
