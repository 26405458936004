import React from "react";
import styled from "styled-components";
import Layout from "../components/layouts";

import Slice from "../components/SliceZone";
import SEO from "./seo";
import { canonicalUrlResolver } from "../utils/routeHelper"


const Page = ({ data, location }) => {
  const page = data.prismic.page;
  if (!page) {
    return null;
  }

  const hideNavMenu = Boolean(page.hide_navigation_menu);
  
  const url = canonicalUrlResolver(location?.pathname);

  return (
    <Layout hideNavMenu={hideNavMenu} canonicalUrl={url}>
      {page.seo.length && <SEO seo={page?.seo} />}
      <ContentContainer className="d-flex flex-column">
        {page.body.map((pageBody, index) => {
          return <Slice slice={pageBody} index={index} />;
        })}
      </ContentContainer>
    </Layout>
  );
};

const ContentContainer = styled.div`
  background-color: white;
`;

export const Title = styled.h1`
  font-size: 39px;
  font-family: "agenda";
  color: ${({ theme }) => theme.colors.navyPrimary};
  text-align: center;
  margin-right: 24px;
`;

export const query = graphql`
  query pageQuery($uid: String!) {
    prismic {
      page(lang: "en-gb", uid: $uid) {
        seo {
          seo_description
          seo_image
          seo_title
          keywords
          business_information {
            ... on PRISMIC_Business_information {
              business_name
              logo
              address
              city
              country
              description
              state_region
              telephone
              url
              zip
              facebook_url
              linkedin_url
              email
            }
          }
        }
        body {
          ... on PRISMIC_PageBodyHero_image {
            type
            label
            primary {
              background_color
              call_to_action_label
              call_to_action_link {
                ... on PRISMIC_Page {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              description
              display_cta
              hero_image
              title
            }
          }
          ... on PRISMIC_PageBodyText {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_PageBodyVideo_panel {
            type
            label
            primary {
              video_background_image
              video_link {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              video_title
              video_title_emphasis
            }
          }
          ... on PRISMIC_PageBodyCard_panel {
            type
            label
            primary {
              link {
                ... on PRISMIC_Page {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              linktext
              bullet_points
              card_panel_image
              description
              image_location
              subtitle
              title
            }
          }
          ... on PRISMIC_PageBodyCall_to_action {
            type
            label
            primary {
              fixed_color
              image
              link {
                ... on PRISMIC_Page {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              link_text
              subtitle
              title1
              cta_type
            }
          }
          ... on PRISMIC_PageBodyFrequent_asked_question {
            type
            label
            fields {
              answer
              question
            }
          }
          ... on PRISMIC_PageBodyPull_quote {
            type
            label
            primary {
              author
              background_color
              quote
            }
          }
          ... on PRISMIC_PageBodyImage_panel {
            type
            label
            primary {
              image
              subtitle
            }
          }
          ... on PRISMIC_PageBodyExpression_of_interest {
            type
            label
            primary {
              show_eoi_form
            }
          }
          ... on PRISMIC_PageBodyCard_form {
            label
            fields {
              field_name
              field_label
              field_placeholder
              field_type
              field_value
              required
            }
            primary {
              card_heading
              card_sub_heading
              form_heading
              form_sub_heading
              form_post_action_id
              bullet_points
              content
            }
            type
          }
          ... on PRISMIC_PageBodyRelated_pages {
            type
            label
            primary {
              title
            }
            fields {
              related_page {
                ... on PRISMIC_Page {
                  _linkType
                  seo {
                    seo_description
                    seo_image
                    seo_title
                  }
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Blog {
                  title
                  seo {
                    seo_description
                    seo_image
                    seo_title
                  }
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
        hide_navigation_menu
      }
    }
  }
`;

export default Page;
