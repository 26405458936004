import React from "react";
import styled from "styled-components";
import { Themes } from "../models/themes";
import DebaArrowsMobileIcon from "../icons/DebaArrowsMobile";
import DebaArrowsDesktopIcon from "../icons/DebaArrowsDesktop";

const handleQuoteDestructure = ({ primary }) => {
  return {
    quote: primary?.quote ? primary?.quote[0].text : "",
    author: primary?.author ? primary?.author[0].text : "",
    color: primary?.background_color,
  };
};

const PullQuoteComponent = (primary) => {
  const quote = handleQuoteDestructure({ ...primary });

  if (!quote) return null;

  return (
    <PullQuoteWrapper
      className="d-flex justify-content-center "
      colorMode={quote.color}
    >
      <ContentWrapper class="d-flex align-items-center postion-relative">
        <TextWrapper>
          <QuoteText>"{quote.quote}"</QuoteText>
          <AuthorText>{quote.author}</AuthorText>
        </TextWrapper>
        <IconWrapper>
          <QuoteIconLarge>
            <DebaArrowsDesktopIcon />
          </QuoteIconLarge>
          <QuoteIconSmall>
            <DebaArrowsMobileIcon />
          </QuoteIconSmall>
        </IconWrapper>
      </ContentWrapper>
    </PullQuoteWrapper>
  );
};

const TextWrapper = styled.div`
  padding: 0 24px;
`;

const ContentWrapper = styled.div``;

const QuoteIconSmall = styled.div`
  @media only screen and (min-width: 1200px) {
    display: none;
  }
`;

const QuoteIconLarge = styled.div`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  @media only screen and (max-width: 1200px) {
    //-38px is due to width and height of SVG icons and their position
    margin: 24px 0 0 -38px;
    left: 50%;
  }
`;

const PullQuoteWrapper = styled.div`
  text-decoration: none;
  padding: ${({ colorMode, theme }) =>
      colorMode === Themes.white ? "0px" : "96px"}
    0
    ${({ colorMode, theme }) => (colorMode === Themes.white ? "96px" : "128px")};
  background-color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.white:
        return "white";
      case Themes.lightGreen:
        return theme.colors.greenBackground;
    }
  }};
  font-family: Agenda;
  font-size: 31px;
  color: ${({ theme }) => theme.colors.navyPrimary};
  cursor: default;
  ${({ theme, colorMode }) =>
    colorMode === Themes.image
      ? `box-shadow: inset 0 0 0 1000px ${theme.colors.navyTransparent};`
      : ""}
  div {
    max-width: 1000px;
  }
`;

const QuoteText = styled.h2`
  font-size: 39px;
  font-family: Agenda;
  padding-bottom: 24px;
  text-align: center;
`;

const AuthorText = styled(QuoteText)`
  text-align: center;
  font-size: 16px;
  opacity: 0.6;
  padding-bottom: 0;
`;

export default PullQuoteComponent;
