import React from "react";
import styled from "styled-components";

const handleVideoDestructure = (primary) => {
  return {
    videoTitleEmphasis: primary?.video_title_emphasis
      ? primary?.video_title_emphasis[0].text
      : "",
    videoLink: primary.video_link?.url,
    backgroundImage: primary.video_background_image?.url,
    videoTitle: primary?.video_title ? primary?.video_title[0].text : "",
  };
};

const VideoPanelDesktopComponent = ({ primary }) => {
  const panel = handleVideoDestructure(primary);
  if (!panel) return null;
  return (
    <VideoPanelWrapper>
      {panel.videoTitle && panel.videoTitleEmphasis && (
        <Title>
          {panel.videoTitle}
          <br />
          <TitleEmphasis2>{panel.videoTitleEmphasis}</TitleEmphasis2>
        </Title>
      )}
      <VideoPanelDesktop image={panel.backgroundImage}>
        <VideoContainer>
          <VideoResponsive>
            <StyledIframe
              src={panel.videoLink}
              width="665"
              height="374"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen={true}
            />
          </VideoResponsive>
        </VideoContainer>
      </VideoPanelDesktop>
    </VideoPanelWrapper>
  );
};

export const VideoPanelDesktop = styled.div`
  justify-content: center;

  padding: 90px 170px 106px 161px;
  display: flex;
  align-items: flex-start;
  background-image: url(${({ image }) => (image ? image : "")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 800px) {
    padding: 45px 85px;
  }

  @media only screen and (max-width: 600px) {
    padding: 25px 40px;
    margin-bottom: 60px;
  }
`;

export const Title = styled.h2`
  font-family: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.lineHeight};
  color: ${(props) => props.theme.textColors.grey};
  margin-bottom: 16px;
  margin-left: 10%;
`;

export const TitleEmphasis2 = styled.strong`
  font-family: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.lineHeight};
  color: ${(props) => props.theme.textColors.greenPrimary};
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 665px;
`;

const VideoResponsive = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  max-width: 665px;
  position: relative;
  height: 0;
`;

export const StyledIframe = styled.iframe`
  border-radius: 20px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const VideoPanelWrapper = styled.div`
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
`;

export default VideoPanelDesktopComponent;
