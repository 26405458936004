import React from "react"

const DebaArrowsDesktopIcon = () => (
  <svg
    width="112px"
    height="88px"
    viewBox="0 0 112 88"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group 19</title>
    <g 
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g transform="translate(-463.000000, -6899.000000)"
      >
        <g transform="translate(0.000000, 6619.000000)">
          <g transform="translate(463.000000, 96.000000)">
            <g transform="translate(0.000000, 184.000000)">
              <g transform="translate(0.000000, 39.000000)">
                <polygon
                  fill="#E0EEE7"
                  points="50 25 0 25 50 45"
                ></polygon>
                <polygon
                  fill="#24A866"
                  fill-rule="nonzero"
                  points="57 0 16 0 57 41"
                ></polygon>
              </g>
              <polygon 
                fill="#24A866"
                fill-rule="nonzero"
                points="112 62 85 62 112 88"
              ></polygon>
              <polygon
                fill="#24A866"
                fill-rule="nonzero"
                points="34 18 34 0 16 0"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default DebaArrowsDesktopIcon
