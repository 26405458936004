import React from "react"

const RightArrowIcon = () => (
  <svg
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>6C769B01-057C-4CE0-BAF4-7995472BCE5A</title>
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        transform="translate(-646.000000, -11721.000000)"
        fill="#00333D"
      >
        <g transform="translate(320.000000, 11409.084324)">
          <g transform="translate(0.000000, 96.592413)">
            <g transform="translate(326.000000, 216.000000)">
              <polygon 
                points="13.3 5.3 11.9 6.7 16.2 11 4 11 4 13 16.2 13 11.9 17.3 13.3 18.7 20 12"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default RightArrowIcon
