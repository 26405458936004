import React from "react";
import styled from "styled-components";
import { Themes } from "../models/themes";
import { routeHelper } from "../../utils/routeHelper";

const handleDestructure = ({ primary }) => {
  const title = primary?.title ? primary?.title[0]?.text : "";
  const description = primary?.description ? primary?.description[0]?.text : "";
  const heroImageUrl = primary?.hero_image?.url;
  const displayCta = primary?.display_cta;
  const callToActionLabel = primary?.call_to_action_label
    ? primary?.call_to_action_label[0]?.text
    : "";
  const backgroundColor = primary?.background_color;
  const callToActionLink = primary?.call_to_action_link?._meta?.uid
    ? primary?.call_to_action_link?._meta?.uid.replace(/__/g, "/")
    : primary?.call_to_action_link?.url;
  return {
    title,
    description,
    heroImageUrl,
    displayCta,
    callToActionLabel,
    backgroundColor,
    callToActionLink,
  };
};

const handleHeroLink = (item) => {
  if (item?.includes(".")) {
    return item;
  }
  if (item?.includes("https://")) return item.split("https://")[1];
  return `/${item}`;
};

const HeroImage = (primary) => {
  const heroImage = handleDestructure(primary);

  if (!heroImage) return null;
  if (heroImage.backgroundColor === Themes.image)
    return (
      <CallToActionLink
        target="_blank"
        backgroundImage={heroImage.heroImageUrl}
      >
        <ImagedHeroWrapper className="d-flex ">
          <HeroContentWrapper className="d-flex ">
            {(heroImage.title || heroImage.description) && (
              <HeroImagedTextWrapper colorMode={heroImage?.backgroundColor}>
                <HeroTitle>{heroImage?.title}</HeroTitle>
                <HeroDescription>{heroImage?.description}</HeroDescription>
                {heroImage.displayCta && heroImage.callToActionLink && (
                  <ButtonWrapper className="d-flex">
                    <ButtonCTA
                      href={`${routeHelper(
                        handleHeroLink(heroImage?.callToActionLink)
                      )}`}
                      className="d-flex flex-row align-items-center justify-content-space-between"
                      colorMode={heroImage.backgroundColor}
                    >
                      <Text colorMode={heroImage.backgroundColor}>
                        {heroImage.callToActionLabel}{" "}
                      </Text>
                    </ButtonCTA>
                  </ButtonWrapper>
                )}
              </HeroImagedTextWrapper>
            )}
          </HeroContentWrapper>
        </ImagedHeroWrapper>
      </CallToActionLink>
    );
  return (
    <CallToActionLink target="_blank" colorMode={heroImage?.backgroundColor}>
      <CallToActionWrapper className="d-flex ">
        <ImageWrapper className="d-flex ">
          <CTAImage src={heroImage.heroImageUrl} className="align-items-ends" />
        </ImageWrapper>
        <ContentWrapper className="d-flex ">
          <TextWrapper colorMode={heroImage?.backgroundColor}>
            <HeroTitle>{heroImage?.title}</HeroTitle>
            <HeroDescription>{heroImage?.description}</HeroDescription>
            {heroImage.displayCta && (
              <ButtonWrapper className="d-flex">
                <ButtonCTA
                  href={`${routeHelper(
                    handleHeroLink(heroImage?.callToActionLink)
                  )}`}
                  className="d-flex flex-row align-items-center justify-content-space-between"
                  colorMode={heroImage.backgroundColor}
                >
                  <Text colorMode={heroImage.backgroundColor}>
                    {heroImage.callToActionLabel}{" "}
                  </Text>
                </ButtonCTA>
              </ButtonWrapper>
            )}
          </TextWrapper>
        </ContentWrapper>
      </CallToActionWrapper>
    </CallToActionLink>
  );
};

const ButtonWrapper = styled.div`
  width: auto;
  margin-top: 24px;
`;
const TextWrapper = styled.div`
  color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.blue:
      case Themes.green:
      case Themes.image:
        return "white";
      case Themes.lightGreen:
      case Themes.white:
        return theme.colors.navyPrimary;
      default:
        return theme.colors.navyPrimary;
    }
  }};

  @media only screen and (max-width: 1200px) {
    padding: 0;
    margin: 0;
  }
`;

const ContentWrapper = styled.div`
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    padding-left: 0;
    margin: 0 auto;
    max-width: 450px;
  }
`;

const HeroContentWrapper = styled(ContentWrapper)`
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.4);
  padding-left: 0;
  @media only screen and (min-width: 800px) {
    padding: 0;
    margin: 0 auto 0 0;
  }
`;

const HeroImagedTextWrapper = styled(TextWrapper)`
  padding: 24px;
`;
const ImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0 0 0 128px;
  @media only screen and (max-width: 1200px) {
    padding: 0;
    margin: 0 auto;
  }
`;

export const CallToActionWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  padding: 112px 0;

  flex-direction: row-reverse;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 112px 24px;
  }

  padding-bottom: ${({ colorMode, theme }) =>
    colorMode === Themes.white ? "0px" : "112px"};
  font-family: Agenda;
  font-size: 31px;

  div {
    max-width: 1000px;
  }
`;

const ImagedHeroWrapper = styled(CallToActionWrapper)`
  justify-content: center;
  margin: auto;
`;

const CallToActionLink = styled.div`
  font-family: Agenda-Semibold;
  min-height: 624px;
  background-color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.blue:
        return theme.colors.navyPrimary;
      case Themes.green:
        return theme.colors.green;
      case Themes.lightGreen:
        return theme.colors.greenBackground;
      case Themes.white:
        return "white";
    }
  }};
  background-image: url(${({ backgroundImage }) => {
    return backgroundImage;
  }});
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px ${({ theme }) => theme.colors.navyTransparent};
  @media (max-width: 380px) {
    width: 380px;
  }
`;

const CTAImage = styled.img`
  min-height: 400px;
  max-width: 550px;
  width: auto;
  height: auto;
  height: 100%;
  object-fit: cover;

  @media only screen and (max-width: 1200px) {
    padding: 0 0 32px 0;
    max-width: 450px;
  }
`;

const HeroDescription = styled.p`
  font-family: Agenda;
  font-size: 25px;
  line-height: 32px;
  max-width: 450px;
`;

const HeroTitle = styled.h1`
  font-size: 48px;
  line-height: 60px;
  font-family: Agenda-Semibold;
  font-weight: bold;

  @media only screen and (max-width: 1200px) {
    max-width: 362px;
    margin: 0;
  }
`;

export const ButtonCTA = styled.a`
  background-color: white;
  width: auto;
  display: flex;
  border-radius: 4px;
  padding: 10px 15px 11px 11px;
  border: 1px solid ${(props) => props.theme.colors.navyPrimary};
  text-decoration: none;
  cursor: pointer;
  background-color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.darkGreen:
      case Themes.image:
      case Themes.green:
      case Themes.blue:
        return "white";
      case Themes.lightGreen:
      case Themes.white:
        return theme.colors.navyPrimary;
    }
  }};
  :hover {
    background-color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.darkGreen:
        case Themes.blue:
        case Themes.image:
        case Themes.green:
          return theme.colors.lightButtonHover;
        case Themes.lightGreen:
        case Themes.white:
          return theme.colors.navyBackgroundHover;
      }
    }};
  }
  :active {
    background-color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.lightGreen:
        case Themes.white:
          return theme.colors.navyBackgroundPressed;
        case Themes.darkGreen:
        case Themes.image:
        case Themes.green:
        case Themes.blue:
          return theme.colors.lightButtonPressed;
      }
    }};
  }
`;

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.lightGreen:
      case Themes.white:
        return "white";
      case Themes.darkGreen:
      case Themes.green:
      case Themes.image:
      case Themes.blue:
        return theme.colors.navyPrimary;
    }
  }};
  :active {
    color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.lightGreen:
        case Themes.white:
        case Themes.darkGreen:
        case Themes.image:
        case Themes.green:
        case Themes.blue:
          return "white";
      }
    }};
  }
`;

export default HeroImage;
