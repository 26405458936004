import React from "react"

const DebaArrowsMobileIcon = () => (
  <svg
    width="77px"
    height="62px"
    viewBox="0 0 77 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>3F23AAD3-1CB7-482B-9E56-BA06456C1C93</title>
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g transform="translate(-169.000000, -7513.000000)">
        <g transform="translate(0.000000, 7205.442831)">
          <g transform="translate(45.000000, 101.000000)">
            <g transform="translate(124.000000, 207.000000)">
              <polygon 
                fill="#E0EEE7"
                points="67.5438596 34.0382318 0 34.0382318 67.5438596 61.2688172"
              ></polygon>
              <polygon
                fill="#24A866"
                fill-rule="nonzero"
                points="77 0 21.6140351 0 77 55.8227001"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default DebaArrowsMobileIcon
