import React from "react";
import styled from "styled-components";

const handleImageDestructure = (primary) => {
  return {
    subtitle: primary?.subtitle ? primary?.subtitle[0].text : "",
    image: primary?.image?.url,
  };
};

const ImagePanel = ({ primary }) => {
  const panel = handleImageDestructure(primary);
  if (!panel) return null;

  return (
    <ImagePanelDesktop className="d-flex justify-content-center flex-column align-items-center">
      <Image alt="image" src={panel.image} />
      <Subtitle>{panel.subtitle}</Subtitle>
    </ImagePanelDesktop>
  );
};

const Subtitle = styled.h2`
  font-size: 14px;
  font-family: helvetica;
  padding-top: 24px;
  text-align: center;
  opacity: 0.6;
`;

const ImagePanelDesktop = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;
const Image = styled.img`
  max-width: 700px;
  max-height: 700px;
  width: auto;
  height: auto;
  object-fit: cover;
  @media only screen and (max-width: 800px) {
    width: 366px;
    height: 331px;
    margin: 0px;
    align-items: center;
    padding-bottom: 8px;
  }
`;

export default ImagePanel;
