import styled from "styled-components";

const TitleEmphasis1 = styled.p`
  font-family: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.lineHeight};
  color: ${(props) => props.theme.textColors.greenPrimary};
`;

export { TitleEmphasis1 }