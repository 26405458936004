import React, { useState } from "react";
import styled from "styled-components";
import BulletPoint from "./components/BulletPointComponent";
import SubmitButtonComponent from "./components/SubmitButtonComponent";
import FormFieldLabelled from "./components/FormFieldLabelledComponent";
import { FormDesktop } from "./components/common/FormDesktop.styled";
import { Paragraph } from "./components/common/CardParagraph.styled";
import axios from "axios";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { mapPrismicHeaderToTag } from '../../utils/headingMapper';

const headingFontSizes = {
  "h1": "48px",
  "h2": "40px",
  "h3": "32px",
  "h4": "24px",
  "h5": "20px",
  "h6": "16px",
};

const headingLineHeights = {
  h1: "48px",
  h2: "40px",
  h3: "32px",
  h4: "30px",
  h5: "28px",
  h6: "24px",
};

function extractHeadingData(sliceData) {
  return {
    tag: mapPrismicHeaderToTag(sliceData?.type),
    text: sliceData.text,
  };
}

const handleCardDestructure = (slice) => {
  const actionId = slice.primary.form_post_action_id ? extractHeadingData(slice.primary.form_post_action_id[0]).text : "";
  
  return {
    heading: extractHeadingData(slice.primary.card_heading[0]),
    sub_heading: extractHeadingData(slice.primary.card_sub_heading[0]),
    content: slice.primary.content[0]?.text ?? '',
    form_heading: extractHeadingData(slice.primary.form_heading[0]),
    form_sub_heading: extractHeadingData(slice.primary.form_sub_heading[0]),
    form_post_url: `/post-card-form/${actionId}`,
    bullets: slice.primary.bullet_points?.map((bullet) => bullet.text) ?? [],
    fields:
      slice.fields?.map((field) => {
        return {
          label: getTextValue(field.field_label),
          name: getTextValue(field.field_name),
          placeholder: getTextValue(field.field_placeholder),
          type: field.field_type.toLowerCase(),
          value: getTextValue(field.field_value),
          required: field.required,
        };
      }) ?? [],
  };
};

function getTextValue(field) {
    if(field && field.length > 0) {
      return field[0].text;
    }

    return '';
}

const CardFormComponent = (sliceObject) => {
  const RECAPTCHA_SITE_KEY = "6LcUzEIdAAAAAATvn6WXT4cDDkFiKL68sSQJuz0d";
  let token = "";

  const panel = handleCardDestructure(sliceObject);

  const [submitState, setSubmitState] = useState({
    submitting: false,
    success: null,
    statusMessage: null,
  });

  if (!panel) {
    return null;
  }

  const handleServerResponse = (success, msg, form) => {
    setSubmitState({
      submitting: false,
      success: success,
      statusMessage: msg,
    });
    setTimeout(() => setSubmitState({ statusMessage: null }), 5 * 1000);
    if (success) {
      form.reset();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.target;
    setSubmitState({ submitting: true });
    const data = new FormData(form);
    data.append("g-recaptcha-response", token);
    
    axios({ method: "post", url: panel.form_post_url, data })
      .then((r) => {
        handleServerResponse(
          true,
          "Thank you. Your request has been received!",
          form
        );
      })
      .catch((error) => {
        handleServerResponse(
          false,
          "There was an error submitting your request. Please try again later!",
          form
        );
      });
  };
  
  return (
    <CardFormPanelDesktop
      className={`d-flex flex-direction-xl-column align-items-xl-flex-start `}
    >
      <Copy className="d-flex flex-column align-items-flex-start">
      <Title as={panel.heading.tag} type={panel.heading.tag}>
          {panel.heading.text}
        </Title>
        <TitleEmphasis as={panel.sub_heading.tag} type={panel.sub_heading.tag}>
          {panel.sub_heading.text}
        </TitleEmphasis>
        <Paragraph>{panel.content}</Paragraph>
        {panel.bullets.map((element, i) => {
            return <BulletPointItem content={element} key={i} />;
          })}
      </Copy>
      <div id="formPanel">
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <form name="contact" method="post" action={panel.form_post_url} onSubmit={submitHandler}>
          <FormDesktop className="test-class">
              <Title as={panel.form_heading.tag} type={panel.form_heading.tag}>
                {panel.form_heading.text}
              </Title>
              <TitleEmphasis 
                as={panel.form_sub_heading.tag}
                type={panel.form_sub_heading.tag}
              >
                {panel.form_sub_heading.text}
              </TitleEmphasis>

            {panel.fields.map(({ label, name, placeholder, type, required, value }) => {
              const inline = type === "checkbox";

              const props = {
                key: name,
                placeholder: placeholder,
                label: label,
                name: name,
                type: type,
                required: required,
                inline: inline,
              };

              if (value || value === 0) {
                props.value = value;
              }

              if (type === "hidden") {
                props.className = "hidden-item";
              }

              return (
                <FormFieldLabelledItem  {...props} />
              );
            })}

            <SubmitBtn />

            {submitState.success ? (
                <SuccessMessage>{submitState.statusMessage}</SuccessMessage>
              ) : (
                <FailureMessage>{submitState.statusMessage}</FailureMessage>
              )}

              <GoogleReCaptcha
                onVerify={(t) => {
                  token = t;
                }}
              />

          </FormDesktop>
        </form>
        </GoogleReCaptchaProvider>
      </div>
    </CardFormPanelDesktop>
  );
};

export const CardFormPanelDesktop = styled.div`
  align-items: flex-start;
  margin-bottom: 119px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  flex-direction: row;

  @media only screen and (max-width: 1200px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const Copy = styled.div`
  @media only screen and (max-width: 800px) {
    margin: 0 auto;
    max-width: 336px;
    width: 100%;
  }
  @media only screen and (max-width: 1200px) {
    margin: 0 auto;
    max-width: 613px;
    width: 100%;
    padding-left: 24px;
  }
`;

export const BulletPointItem = styled(BulletPoint)`
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const Title = styled.p`
  max-width: 700px;
  text-align: start;
  max-width: 700px;
  font-family: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-weight: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.weight};
  color: ${(props) => props.theme.textColors.grey};
  margin-bottom: 16px;

  @media only screen and (max-width: 1200px) {
    max-width: 700px;
  }

  font-size: ${(props) => headingFontSizes[props.type]};
  line-height: ${(props) => headingLineHeights[props.type]};
  }};
`;
export const TitleEmphasis = styled(Title)`
  font-weight: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.weight};
  color: ${(props) => props.theme.textColors.greenPrimary};
`

export const FormFieldLabelledItem = styled(FormFieldLabelled)`
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const CheckboxText = styled.p`
  max-width: 347px;
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${(props) => props.theme.textColors.gray};
  text-decoration: underline;
`;
export const SuccessMessage = styled.span`
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${(props) => props.theme.textColors.gray};
`;

export const FailureMessage = styled(SuccessMessage)`
  color: ${(props) => props.theme.textColors.red};
`;

export const SubmitBtn = styled(SubmitButtonComponent)`
  background-color: ${(props) => props.theme.colors.navyPrimary};
  border-radius: 4px;
  padding: 11px 16px 13px;
  display: flex;
  align-items: flex-start;
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${(props) => props.theme.textColors.white};
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export default CardFormComponent;
