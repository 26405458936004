import React from "react";
import styled from "styled-components";
import BulletPoint from "./components/BulletPointComponent";
import { routeHelper } from "../../utils/routeHelper";
import { ButtonCTA, Text } from "../slices/HeroImage";
import { Image } from "./components/common/CardImage.styled";
import { TitleEmphasis1 } from "./components/common/CardTitleEmphasis1.styled";
import { Paragraph } from "./components/common/CardParagraph.styled";
import { Title } from "./components/common/CardTitle.styled";

const handleCardDestructure = (primary) => {
  return {
    link: primary?.link ? primary?.link : "",
    linkText: primary?.linktext ? primary?.linktext[0]?.text : "",
    h2: primary?.title ? primary?.title[0].text : "",
    h2Sub: primary?.subtitle ? primary?.subtitle[0].text : "",
    body: primary?.description ? primary?.description[0].text : "",
    bullets: primary?.bullet_points
      ? primary.bullet_points.map((bullet) => bullet.text)
      : null,
    image: primary.card_panel_image?.url,
    leftPosition: primary.image_location === "Left" ? true : false,
  };
};

const handleCardLink = (item) => {
  if (item.link?._meta?.uid)
    return `/${item.link?._meta?.uid.replace(/__/g, "/")}`;
  const route = item.link?.url;
  if (!route) return "/";
  if (route.includes(".")) {
    return route;
  }
  return route.split("https://")[1];
};

const ImageTextPanelDesktopComponent = ({ primary }) => {
  const panel = handleCardDestructure(primary);

  if (!panel) return null;
  return (
    <ImageTextPanelDesktop
      className={`d-flex flex-direction-xl-column align-items-xl-flex-start `}
      left={panel.leftPosition}
    >
      <Copy className="d-flex flex-column align-items-flex-start">
        <Title>
          {panel.h2}
          <TitleEmphasis1>{panel.h2Sub}</TitleEmphasis1>
        </Title>
        <Paragraph>{panel.body}</Paragraph>
        {panel.bullets &&
          panel.bullets.map((element, i) => {
            return <BulletPointItem content={element} />;
          })}
        {panel.link && (
          <StyledButton
            href={`${routeHelper(handleCardLink(panel))}`}
            className="d-flex align-items-center "
            colorMode={"Blue"}
          >
            <Text colorMode={"Blue"}>{panel.linkText}</Text>
          </StyledButton>
        )}
      </Copy>
      <Image alt="image" src={panel.image} left={panel.leftPosition} />
    </ImageTextPanelDesktop>
  );
};

const StyledButton = styled(ButtonCTA)`
  margin: 32px auto 0;
`;

export const ImageTextPanelDesktop = styled.div`
  align-items: flex-start;
  margin-bottom: 119px;
  max-width: 1200px;
  margin: 0 auto;
  ${({ left }) => {
    return left ? "flex-direction: row-reverse" : "flex-direction: row";
  }}};

  @media only screen and (max-width: 1200px) {
    align-items: center;
    flex-direction: column;
  }
`;
export const Copy = styled.div`
  @media only screen and (max-width: 800px) {
    margin: 0 auto;
    max-width: 336px;
    width: 100%;
  }
  @media only screen and (max-width: 1200px) {
    margin: 0 auto;
    max-width: 613px;
    width: 100%;
    padding-left: 24px;
  }
`;

export const BulletPointItem = styled(BulletPoint)`
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export default ImageTextPanelDesktopComponent;
