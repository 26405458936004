import styled from "styled-components";

const Paragraph = styled.p`
  max-width: 700px;
  font-family: ${(props) =>
    props.theme.fonts.desktopTextLargeBlackDefaultLeft.family};
  font-size: ${(props) =>
    props.theme.fonts.desktopTextLargeBlackDefaultLeft.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktopTextLargeBlackDefaultLeft.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktopTextLargeBlackDefaultLeft.lineHeight};
  color: ${(props) => props.theme.textColors.grey};
  margin-bottom: 31px;
  opacity: 0.7;

  @media only screen and (max-width: 800px) {
    max-width: 450px;
    padding-right: 20px;
  }
`;

export { Paragraph };