import React from "react";
import styled from "styled-components";

// For styling any nested component, Overlay use the styled method.
// This method works perfectly on all of your any third-party component, as long as they attach the passed className prop to a DOM element.
// Here an example: https://gist.github.com/Miniplop/80b042d1b44293329ef7332fd47b770c

// Nested component imports
import IconSuccess from "./IconSuccessComponent";

export const BulletPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const IconSuccessItem = styled(IconSuccess)`
  margin-right: 12px;
`;
export const BulletText = styled.p`
  width: 474px;
  font-family: ${props =>
            props.theme.fonts.desktop4HeadingMBlackDefaultLeft.family};
  font-size: ${props =>
            props.theme.fonts.desktop4HeadingMBlackDefaultLeft.size};
  font-weight: ${props =>
            props.theme.fonts.desktop4HeadingMBlackDefaultLeft.weight};
  line-height: ${props =>
            props.theme.fonts.desktop4HeadingMBlackDefaultLeft.lineHeight};
  color: rgba(0, 51, 61, 1);

  @media only screen and (max-width: 800px){
      width: 300px;
  }

`;

const BulletPointComponent = ({ className, children, content }) => {
      return (
            <BulletPoint className={className} >
                  <IconSuccessItem />
                  <BulletText>{content}</BulletText>
            </BulletPoint>
      );
};

export default BulletPointComponent;