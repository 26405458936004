import styled from "styled-components";

export const Image = styled.img`
  width: 613px;
  height: 555px;
  object-fit: cover;

  ${({ left }) => {
    return left ? "margin-right: 80px" : "margin-left: 80px";
  }}};

  @media only screen and (max-width: 1200px) {
    margin: 0px;
    align-items: center;
    padding-top: 40px;
  }
  @media only screen and (max-width: 800px) {
    width: 366px;
    height: 331px;
  }
`;