import styled from "styled-components";

const Title = styled.h2`
  max-width: 700px;
  font-family: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.lineHeight};
  color: ${(props) => props.theme.textColors.grey};
  margin-bottom: 16px;

  @media only screen and (max-width: 1200px) {
    max-width: 700px;
  }
`;

export { Title };